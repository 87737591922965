import Button from '../components/button'
import Layout from '../components/layout'
import Line from '../components/line'
import React from 'react'
import Wrapper from '../components/wrapper'
import { breakpoints } from '../variables'
import { css } from '@emotion/react'

export default React.memo(() => (
  <Layout>
    <Wrapper
      className={css`
        margin: 3rem auto;
        max-width: 800px;
        text-align: center;

        @media (min-width: ${breakpoints.medium}px) {
          margin: 4rem auto;
        }

        @media (min-width: ${breakpoints.tablet}px) {
          margin: 5rem auto;
        }

        @media (min-width: ${breakpoints.laptop}px) {
          margin: 7.5rem auto;
        }
      `}
    >
      <h1
        css={css`
          @media (min-width: ${breakpoints.tablet}px) {
            font-size: 46px;
          }
        `}
      >
        Pagina niet gevonden
      </h1>
      <Line
        className={css`
          margin-left: auto;
          margin-right: auto;
        `}
      />
      <p
        css={css`
          margin-bottom: 3rem;
        `}
      >
        We doen erg ons best om een foutloze website ervaring aan te bieden,
        maar helaas hebben we de opgevraagde pagina niet kunnen vinden. Je kunt
        terugkeren naar de homepagina of specifieke informatie vinden via het
        menu.
      </p>
      <Button external={false} link="/">
        Naar de homepagina
      </Button>
    </Wrapper>
  </Layout>
))
